@import 'plex.module';

@mixin palette-dark {
  color: white;
  background: $plex-palette-primary-darkgrey;
}

@mixin palette-light {
  color: $plex-palette-primary-darkgrey;
  background: $plex-palette-neutral-lightestgrey;

  a {
    color: $plex-palette-primary-blue;
    text-decoration: none;

    &:hover {
      color: $plex-palette-primary-blue-hover;
    }
  }
}

@mixin vertical-spacing($spacing) {
  & > * {
    margin-bottom: $spacing;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
