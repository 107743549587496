$plex-palette-primary-darkgrey: rgb(0, 32, 64);
$plex-palette-primary-blue: rgb(0, 80, 240);
$plex-palette-neutral-lightgrey: rgb(230, 230, 230);
$plex-palette-neutral-lightestgrey: rgb(248, 248, 248);
$plex-palette-icon-icongrey: rgb(128, 128, 144);
$plex-palette-valid: rgb(0, 192, 0);
$plex-palette-invalid: rgb(253, 97, 79);
$plex-palette-secondary-midgrey: rgb(184, 184, 196);

$plex-palette-validation-text: #F92A3E;

$plex-palette-task-inprogress-standard: rgb(255, 174, 87);
$plex-palette-primary-blue-hover: scale_color($plex-palette-primary-blue, $lightness: -30%);

$plex-spacing-xxs: 4px;
$plex-spacing-xs: 8px;
$plex-spacing-s: 12px;
$plex-spacing-m: 16px;
$plex-spacing-l: 20px;
$plex-spacing-xl: 24px;
$plex-spacing-xxl: 28px;
$plex-spacing-xxl-2: 32px;
$plex-spacing-xxl-3: 36px;
$plex-spacing-xxl-4: 40px;
$plex-spacing-xxl-5: 44px;
$plex-spacing-xxl-6: 48px;

@mixin
content {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

  h1, h2, h3, h4, h5, h6, p {
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  h1 {
    font-size: 36px;
    font-weight: normal;
  }

  h2 {
    font-size: 22px;
    font-weight: normal;
  }

  h3 {
    font-size: 18px;
    font-weight: bold;
  }
}
