@import "../styles/plex.module";
@import "../styles/palettes.module";

.root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.plexusLogo {
  margin: $plex-spacing-l 0 0 $plex-spacing-l;
  width: 120px;
  height: 32px;
}

.plexusLogoContainer {
  position: absolute;
  @include palette-dark;
}

.promotionWizardLogo {
  max-height: 42px;
  padding-top: 11px;

  img {
    max-height: 22.5px;
    max-width: 370px;
    width: 100%;
  }
}

.left {
  @include palette-dark;
  @include content;
  @include vertical-spacing($plex-spacing-xxl-4);

  flex-shrink: 1;
  font-size: 20px;
  padding: 80px 50px 50px 50px;
}

.body {
  @include palette-light;
  @include content;
  @include vertical-spacing($plex-spacing-l);

  padding: 50px;
  flex-shrink: 1;
  flex-grow: 1;
}

.smallText {
  border-top: 1px solid white;
  padding-top: $plex-spacing-xxl-4;
  font-size: 14px;
}

@media screen and (min-width: 1024px) {
  .root {
    flex-direction: row;
  }

  .left {
    max-width: 550px;
  }

  .body {
    padding: 80px 70px 50px 70px;
  }
}

@media screen and (min-width: 1280px) {
  .left {
    padding: 150px 90px 90px 90px;
  }

  .body {
    padding: 150px 90px 47px 90px;
  }
}
