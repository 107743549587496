@import '../../styles/plex.module';
@import '../../styles/palettes.module';

.root {
  position: relative;
  pointer-events: none;
}

.contentContainer {
  position: absolute;
  bottom: -100%;
  padding-bottom: 12px;
}

.content {
  border: 1px solid $plex-palette-secondary-midgrey;
  border-radius: 3px;
  background: white;
}
