@import '../styles/plex.module';
@import '../styles/palettes.module';

.root {
  max-width: 450px;

  &:focus {
    outline: none;
  }
}

.infoIcon {
  display: inline-block;
  cursor: pointer;
  margin-left: $plex-spacing-xs;

  & > svg {
    color: $plex-palette-icon-icongrey;
  }
}
