@import '../styles/plex.module';
@import '../styles/palettes.module';

.root {
  @include vertical-spacing($plex-spacing-l);

  display: flex;
  flex-direction: column;
}

.header {
  margin-bottom: $plex-spacing-xxl;
}
