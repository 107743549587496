@import '../styles/plex.module';
@import '../styles/palettes.module';

.text {
  user-select: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-variant: small-caps; // For IE, which does not support all-small-caps
  font-variant: all-small-caps;

  & div, & .error {
    font-variant: none;
  }

  input {
    margin-top: $plex-spacing-xxs;
    color: $plex-palette-primary-darkgrey;
    height: 55px;
    border-radius: 3px;
    border: none;
    font-size: 16px;
    padding: $plex-spacing-m;
  }
}

.passwordCalloutComponent {
  margin-top: $plex-spacing-s;
  margin-bottom: -$plex-spacing-s;

  max-width: 450px;
}

.checkbox {
  user-select: none;

  input {
    margin-right: $plex-spacing-xs;
  }
}

input.invalidBorder {
  border: 1px solid $plex-palette-invalid;
}

input.validBorder {
  border: 1px solid $plex-palette-valid;
}

.error {
  color: $plex-palette-validation-text;
  margin-top: $plex-spacing-xxs;
  font-size: 12px;
  margin-bottom: 0;
}
