@import '../styles/plex.module';

button.root {
  @include content;

  cursor: pointer;
  width: 195px;
  min-height: 50px;
  line-height: 50px;
  text-align: center;
  display: inline-block;
  background: $plex-palette-primary-blue;
  color: white;
  border-radius: 4px;
  user-select: none;
  border: none;
  font-size: 16px;

  &:hover {
    background: $plex-palette-primary-blue-hover;
    color: white;
  }

  &:disabled {
    background: $plex-palette-icon-icongrey;
    color: white;
    cursor: default;
  }
}
