@import '../styles/plex.module';
@import '../styles/palettes.module';

.root {
  @include vertical-spacing($plex-spacing-xs);
  @include content;

  padding: $plex-spacing-xl;
  font-size: 14px;
}

.heading {
  margin-bottom: $plex-spacing-m;
  font-weight: bold;

  svg {
    display: none;
  }
}

.text {
  margin-bottom: $plex-spacing-m;
}

.withPassword {
  .heading {
    margin-bottom: $plex-spacing-l;
    padding: $plex-spacing-s;
    border-radius: 4px;
    border: 2px solid $plex-palette-neutral-lightgrey;
    font-weight: bold;
    line-height: 26px;

    svg {
      display: inline;
      margin-top: -1px;
      font-size: 24px;
      color: $plex-palette-task-inprogress-standard;
      margin-right: $plex-spacing-s;
      vertical-align: middle;
    }
  }

  .text {
    font-weight: bold;
  }
}

.requirementValid svg {
  color: $plex-palette-valid;
  margin-right: $plex-spacing-s;
}

.requirementInvalid svg {
  color: $plex-palette-invalid;
  margin-right: $plex-spacing-s;
}

.requirementIgnored svg {
  color: $plex-palette-icon-icongrey;
  margin-right: $plex-spacing-s;
}

.ruleChildren {
  @include vertical-spacing($plex-spacing-xs);

  margin-top:  $plex-spacing-xs;
  margin-left: $plex-spacing-xxl;
}
