@import '../../styles/plex.module';
@import '../../styles/palettes.module';

.root {
  position: relative;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.arrowBorder {
  position: absolute;
  top: -12px;

  width: 0px;
  height: 0px;
  border: 12px solid transparent;
  border-top-color: $plex-palette-secondary-midgrey;
}

.arrow {
  position: absolute;
  top: -13px;

  width: 0px;
  height: 0px;
  border: 12px solid transparent;
  border-top-color: white;
}
